import {useEffect, useState} from "react";
import {useMoralis} from "react-moralis";
import {neuroABI} from "../abi/neuro";

function useNeuroClaimed(season, tokenId) {

    const {isWeb3Enabled, enableWeb3, web3} = useMoralis();
    const NEURO_ADDRESS = "0xe71946B31Bda22526b2dE702BCA8B457ef85C4F1";

    const [value, setValue] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!isWeb3Enabled) {
            enableWeb3();
        }
    }, [isWeb3Enabled, enableWeb3]);

    useEffect(() => {
        if (isWeb3Enabled) {
            setIsLoading(true);
            const contract = new web3.eth.Contract(neuroABI, NEURO_ADDRESS);
            contract.methods.seasonClaimedByTokenId(season, tokenId)
                .call((err, res) => {
                    if (err) {
                        setError(err);
                        return;
                    }
                    setValue(res === true ? "YES" : "NO");
                    //return res;
                })
                .catch((err) => {
                    setError(err);
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [season, tokenId, isWeb3Enabled, web3.eth.Contract])

    return [value, isLoading, error];
}

export default useNeuroClaimed;