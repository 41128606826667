/**
 * Checks if the provided stashId is valid
 *
 * @param stashId the stashId the check
 * @param min the minimum value accepted
 * @param max the maximum value accepted
 * @returns {boolean} true if the stashId:
 * - is not numeric
 * - is an empty string
 * - is not between the min and max values
 */
export function isStashIdInvalid(stashId, min, max) {
    return (Number(stashId) < min || Number(stashId) > max
        || (stashId).trim() === ""
        || stashId.match(/[^$,.\d]/)
        || isNaN(Number(stashId)))
}
