import {type, div, clear} from "./terminal"

// Fast config for the typer
// const FAST = {
//     wait: 15,
//     initialWait: 100
// };

export async function boot() {

    clear();

    //let intro = await getScreen("input");
    let intro = div();
    await type("Welcome to GΞAR TΞRMINAL v.01 alpha", {
        initialWait: 1000, finalWait: 1000, useContainer: false
    }, intro);

    // await type(" v.01", { initialWait: 50, useContainer: true }, intro);
    // await type(" alpha", { initialWait: 100, wait: 15, useContainer: true, stopBlinking: false }, intro);

    await type([
        "Loading........................",
        ".",
        "."
    ], {stopBlinking: false}, intro);

    await type([
        "> LIST FEATURES",
        "> - Send and receive transmissions between GΞAR NFTs",
        "> - Browse through your GΞAR",
        "> - Check the rarity of your GΞAR",
        "> - Browse through others GEAR",
        "> - *********************",
        "> - *********************",
        "> - *********************",
        ],
        { lineWait: 250 , stopBlinking: false}, intro);

    await type([
        "Loading........................",
        ".",
        "."
    ], {}, intro);

    await type([
            "> LIST REQUIREMENTS",
            "> - Own at least one GΞAR NFT",
            "> - Owning multiple GΞAR NFTs gives you more abilities"

        ],
        { lineWait: 250 }, intro);

    await type([
        "Loading........................",
        ".",
        "."
    ], {}, intro);

    await type("> Be among the first TΞRMINAL users", {}, intro);
    await type("> Start by connecting with metamask", {}, intro);
    await type("> Waiting for connected account...", {}, intro);
    //intro.remove();
}