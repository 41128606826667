import React from 'react';
import {Square, Link, Heading} from "@chakra-ui/react";
import {Link as LinkRouter} from 'react-router-dom';

/**
 * 404 page
 */
function NotFound() {
    return (
        <Square mt={20} p={10} flexDir="column">
            <Heading>
                404 Page
            </Heading>
            <Link m={3} p={3} as={LinkRouter} to="/">
                Back to Homepage
            </Link>
        </Square>
    )
}

export default NotFound;