import React, {useEffect, useRef, useState} from "react";
import classes from "./Channel.module.css"
import {useMoralis, useMoralisQuery, useMoralisSubscription} from "react-moralis";
import {isMessageToSendInvalid} from "../helpers/chat";
import {
    Box,
    Heading,
    HStack,
    IconButton,
    Input,
    Select,
    Stack,
    Text,
} from "@chakra-ui/react";
import {RiArrowRightSLine} from "react-icons/all";

function Channel({channel, from, setSendFrom, to, setSendTo, idList, userNFTsInChannel}) {

    const {Moralis} = useMoralis();
    const Terminal = Moralis.Object.extend("Terminal");

    const [messageToSend, setMessageToSend] = useState("");
    const [isMessageInvalid, setIsMessageInvalid] = useState(true);
    const [sending, setSending] = useState(false);
    const messageInputRef = useRef();

    const [chatMessages, setChatMessages] = useState([]);
    const [chatLimit, setChatLimit] = useState(500);
    const mainQuery = new Moralis.Query(Terminal);
    mainQuery.equalTo("channel", channel);
    const messagesContainerRef = useRef();
    const lastMessageRef = useRef();

    // Get history messages
    const {data, error, isLoading} = useMoralisQuery(Terminal, query =>
            mainQuery
                .descending("updatedAt")
                .limit(chatLimit)

        , [chatLimit, channel]);


    // Subscribe to the channel to get new messages
    useMoralisSubscription(Terminal, query =>
            mainQuery
        , [channel], {
            onCreate: data => {
                let newMessage = JSON.stringify(data, null, 2)
                newMessage = JSON.parse(newMessage);
                let temp = [
                    ...chatMessages,
                    newMessage
                ];
                setChatMessages(temp);
            },
            enabled: true,
        });

    function onTextToSendChange(e) {
        if (isMessageToSendInvalid(e.target.value)) {
            setIsMessageInvalid(true);
        } else {
            setIsMessageInvalid(false);
        }
        setMessageToSend(e.target.value)
    }

    function onSelectFromChange(e) {
        setSendFrom(e.target.value)

        if (e.target.value === from) {
            setSendTo(to);
        } else if (e.target.value === to) {
            setSendTo(from);
        }
        // setChannel(getChannelName(from, to));
    }

    async function sendMessage() {
        if (isMessageInvalid) {
            //console.log("Add some text to your transmission!");
        } else {
            // Fix for prevent sending the message multiple times
            if (sending === false) {
                setSending(true);
                //console.log("preparing to send...");
                const messageToSend = messageInputRef.current.value;
                const message = new Terminal();
                message.set("message", messageToSend);
                message.set("fromId", from);
                message.set("toId", to);
                message.set("channel", channel);
                await message.save()

                // console.log("Message sent!")
                setMessageToSend("");
                setSending(false);

                // Focus the input after sending the message
                messageInputRef.current.focus();
            }
        }
    }

    useEffect(() => {
        if (data) {
            let chatHistory = JSON.stringify(data, null, 2);
            chatHistory = JSON.parse(chatHistory)

            // Fix for last X chats
            chatHistory = chatHistory.reverse();
            setChatMessages(chatHistory);
        }
    }, [data]);

    useEffect(() => {
        if (chatMessages && chatMessages.length > 0) {
            lastMessageRef.current = messagesContainerRef.current.lastChild;

            if (lastMessageRef.current) {
                // Looks nice but it could be an issue if we load eg. 500 messages
                lastMessageRef.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        }
    }, [chatMessages]);

    return (
        <Box width="100%" height="100%">

            <Box w="100%" h="85%">
                {isLoading &&
                <Heading>Loading Messages</Heading>
                }

                {chatMessages && chatMessages.length > 0 &&
                <Box width="98%" height="100%" overflow={"scroll"}
                     ref={messagesContainerRef}
                     backgroundColor={"terminal.background"}
                >
                    {chatMessages.map((item, index) => (
                        <Stack key={index} border={"1px solid #009600"} p={2} m={3}>
                            <Text fontSize={"xl"} color={item.fromId === from ? "#1AFF80" : "#FFFFFF"}>
                                #{item.fromId} >>
                                {/*-> STASH #{item.toId}*/}
                            </Text>
                            <Text fontSize={"xl"}>
                                {item.message}
                            </Text>
                            {/*<Divider color="#63cd18"/>*/}
                        </Stack>
                    ))}
                </Box>
                }
            </Box>
            <HStack w="100%" h="15%" px={3}>
                <Select value={from}
                        w="28%"
                        onChange={onSelectFromChange}
                    // ref={selectFromRef}
                        variant="filled" py={3} px={1}>
                    {
                        userNFTsInChannel.map((option) => (
                            <option key={option} value={option}>
                                STASH #{option} >
                            </option>
                        ))
                    }
                </Select>
                    <Input
                        w="60%"
                        disabled={sending}
                        className={classes.sendInput}
                        placeholder="ENTER MESSAGE..." size="md"
                        value={messageToSend}
                        onChange={onTextToSendChange}
                        ref={messageInputRef}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                sendMessage();
                            }
                        }}
                    />
                    <IconButton
                        aria-label="Send"
                        isDisabled={isMessageInvalid}
                        isLoading={sending}
                        className={classes.sendButton}
                        icon={<RiArrowRightSLine/>}
                        onClick={sendMessage}
                    />
            </HStack>
        </Box>
    )
}

export default Channel;