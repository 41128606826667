export const neuroABI = [{
        "inputs":[

        ],
        "stateMutability":"nonpayable",
        "type":"constructor"
      },
      {
        "anonymous":false,
        "inputs":[
          {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
          },
          {
            "indexed":true,
            "internalType":"address",
            "name":"spender",
            "type":"address"
          },
          {
            "indexed":false,
            "internalType":"uint256",
            "name":"value",
            "type":"uint256"
          }
        ],
        "name":"Approval",
        "type":"event"
      },
      {
        "anonymous":false,
        "inputs":[
          {
            "indexed":true,
            "internalType":"address",
            "name":"previousOwner",
            "type":"address"
          },
          {
            "indexed":true,
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
          }
        ],
        "name":"OwnershipTransferred",
        "type":"event"
      },
      {
        "anonymous":false,
        "inputs":[
          {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
          },
          {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
          },
          {
            "indexed":false,
            "internalType":"uint256",
            "name":"value",
            "type":"uint256"
          }
        ],
        "name":"Transfer",
        "type":"event"
      },
      {
        "inputs":[

        ],
        "name":"NeuroCreditPerTokenId",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"owner",
            "type":"address"
          },
          {
            "internalType":"address",
            "name":"spender",
            "type":"address"
          }
        ],
        "name":"allowance",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"spender",
            "type":"address"
          },
          {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
          }
        ],
        "name":"approve",
        "outputs":[
          {
            "internalType":"bool",
            "name":"",
            "type":"bool"
          }
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"account",
            "type":"address"
          }
        ],
        "name":"balanceOf",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[

        ],
        "name":"claimAllForOwner",
        "outputs":[

        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"tokenId",
            "type":"uint256"
          }
        ],
        "name":"claimById",
        "outputs":[

        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"ownerIndexStart",
            "type":"uint256"
          },
          {
            "internalType":"uint256",
            "name":"ownerIndexEnd",
            "type":"uint256"
          }
        ],
        "name":"claimRangeForOwner",
        "outputs":[

        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"amountDisplayValue",
            "type":"uint256"
          }
        ],
        "name":"daoMint",
        "outputs":[

        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"NeuroCreditDisplayValue",
            "type":"uint256"
          }
        ],
        "name":"daoSetNeuroCreditPerTokenId",
        "outputs":[

        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"season_",
            "type":"uint256"
          }
        ],
        "name":"daoSetSeason",
        "outputs":[

        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"season_",
            "type":"uint256"
          },
          {
            "internalType":"uint256",
            "name":"NeuroCreditDisplayValue",
            "type":"uint256"
          }
        ],
        "name":"daoSetSeasonAndNeuroCreditPerTokenID",
        "outputs":[

        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"tokenIdStart_",
            "type":"uint256"
          },
          {
            "internalType":"uint256",
            "name":"tokenIdEnd_",
            "type":"uint256"
          }
        ],
        "name":"daoSetTokenIdRange",
        "outputs":[

        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"gearContractAddress_",
            "type":"address"
          }
        ],
        "name":"daoSetgearContractAddress",
        "outputs":[

        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[

        ],
        "name":"decimals",
        "outputs":[
          {
            "internalType":"uint8",
            "name":"",
            "type":"uint8"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"spender",
            "type":"address"
          },
          {
            "internalType":"uint256",
            "name":"subtractedValue",
            "type":"uint256"
          }
        ],
        "name":"decreaseAllowance",
        "outputs":[
          {
            "internalType":"bool",
            "name":"",
            "type":"bool"
          }
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[

        ],
        "name":"gearContract",
        "outputs":[
          {
            "internalType":"contract IERC721Enumerable",
            "name":"",
            "type":"address"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[

        ],
        "name":"gearContractAddress",
        "outputs":[
          {
            "internalType":"address",
            "name":"",
            "type":"address"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"spender",
            "type":"address"
          },
          {
            "internalType":"uint256",
            "name":"addedValue",
            "type":"uint256"
          }
        ],
        "name":"increaseAllowance",
        "outputs":[
          {
            "internalType":"bool",
            "name":"",
            "type":"bool"
          }
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[

        ],
        "name":"name",
        "outputs":[
          {
            "internalType":"string",
            "name":"",
            "type":"string"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[

        ],
        "name":"owner",
        "outputs":[
          {
            "internalType":"address",
            "name":"",
            "type":"address"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[

        ],
        "name":"renounceOwnership",
        "outputs":[

        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[

        ],
        "name":"season",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          },
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "name":"seasonClaimedByTokenId",
        "outputs":[
          {
            "internalType":"bool",
            "name":"",
            "type":"bool"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[

        ],
        "name":"symbol",
        "outputs":[
          {
            "internalType":"string",
            "name":"",
            "type":"string"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[

        ],
        "name":"tokenIdEnd",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[

        ],
        "name":"tokenIdStart",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[

        ],
        "name":"totalSupply",
        "outputs":[
          {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
          }
        ],
        "stateMutability":"view",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"recipient",
            "type":"address"
          },
          {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
          }
        ],
        "name":"transfer",
        "outputs":[
          {
            "internalType":"bool",
            "name":"",
            "type":"bool"
          }
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"sender",
            "type":"address"
          },
          {
            "internalType":"address",
            "name":"recipient",
            "type":"address"
          },
          {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
          }
        ],
        "name":"transferFrom",
        "outputs":[
          {
            "internalType":"bool",
            "name":"",
            "type":"bool"
          }
        ],
        "stateMutability":"nonpayable",
        "type":"function"
      },
      {
        "inputs":[
          {
            "internalType":"address",
            "name":"newOwner",
            "type":"address"
          }
        ],
        "name":"transferOwnership",
        "outputs":[

        ],
        "stateMutability":"nonpayable",
        "type":"function"
      }
    ]