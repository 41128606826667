import React, {useEffect, useState} from "react";
import {useMoralis} from "react-moralis";
import {Button, HStack, Text, useToast} from "@chakra-ui/react";
import {FaWallet, FaEthereum} from 'react-icons/fa';
import classes from "./Authentication.module.css"
import {useHistory} from "react-router-dom";

/**
 * Component that handles the authentication process
 */
function Authentication() {

    const history = useHistory();

    const {
        authenticate,
        isAuthenticated,
        isAuthenticating,
        isLoggingOut,
        logout,
        user,
    } = useMoralis();

    // TODO - Resolve ENS and .cryto domains

    useEffect(() => {
        const ethereum = window.ethereum;
        if (!ethereum) {
            setWarning("This app requires Metamask. Please install it.")
        }
    }, [])

    const [warning, setWarning] = useState();

    const toastIdForAuthError = "authError"
    const toastIdForError = "otherError"
    const toast = useToast();

    function showErrorToast(id, title, description) {
        if (!toast.isActive(id)) {
            toast({
                id: id,
                title: title,
                description: description,
                status: "error",
                duration: 3000,
                isClosable: true,
                position: "top",
            })
        }
    }

    return (
        <HStack>
            {/*TODO - move this warning lower*/}
            {warning &&
            <Text className={classes.warning}>
                {warning}
            </Text>
            }

            {/* If user is not authenticated and has metamask installed */}
            {!isAuthenticated && window.ethereum &&
            <Button
                variant="ghost"
                className={classes.button}
                isLoading={isAuthenticating} loadingText="PROCESSING..."
                leftIcon={<FaWallet/>} aria-label="CONNECT" rounded="xl"
                onClick={
                    () => {
                        authenticate({
                            signingMessage : "Gear Terminal login",
                            onComplete: () => {
                                // Redirect to Home page
                                history.replace('/');
                            },
                            onError: (error) => {
                                showErrorToast(toastIdForAuthError, "Error Authenticating", error.message)
                            },
                            onSuccess: () => {
                                // TODO
                            }
                            // TODO - replace with async/await
                        }).then()
                    }
                }>
                CONNECT
            </Button>
            }

            {isAuthenticating &&
            <Button
                variant="ghost"
                className={classes.button}
                aria-label="Address" rounded="xl" ml={2}
                onClick={
                    () => {
                        logout({
                            onError: (error) => {
                                showErrorToast(toastIdForError, "Error", error.message)
                            }
                        }).then()
                    }
                }
            >
                CANCEL
            </Button>
            }

            {isAuthenticated &&
            <>
                <Button
                    variant="ghost"
                    className={classes.address}
                    leftIcon={<FaEthereum/>}
                    aria-label="Address" rounded="xl"
                >
                    <Text isTruncated> {user.get("ethAddress")}</Text>
                </Button>
                <Button
                    variant="ghost"
                    className={classes.button}
                    isLoading={isLoggingOut}
                    leftIcon={<FaWallet/>}
                    aria-label="DISCONNECT" rounded="xl" ml={2}
                    onClick={
                        () => {
                            logout({
                                onError: (error) => {
                                    showErrorToast(toastIdForError, "Error", error.message)
                                }
                            }).then(() => {
                                history.replace('/');
                            })
                        }
                    }
                >
                    DISCONNECT
                </Button>
            </>
            }
        </HStack>
    )
}

export default Authentication;

