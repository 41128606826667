import {
    Box,
    Button, FormControl, FormLabel,
    HStack, Icon, IconButton,
    NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper,
    Select,
    Spacer,
    Square, Stack,
    Text, useToast,
    VStack
} from "@chakra-ui/react";
import classes from "./Transmissions.module.css"
import React, {useEffect, useRef, useState} from "react";
import {useMoralis, useMoralisQuery, useMoralisSubscription} from "react-moralis";
import Channel from "./Channel";
import {getChannelName} from "../helpers/chat";
import {BsArrowLeftRight, RiCloseLine} from "react-icons/all";
import {isStashIdInvalid} from "../helpers/helper"

function Transmissions({nftList}) {

    const {Moralis} = useMoralis();
    const Terminal = Moralis.Object.extend("Terminal");

    const selectFromRef = useRef();
    const sendToInputRef = useRef();

    const [sendFrom, setSendFrom] = useState();
    const [sendTo, setSendTo] = useState();
    const [invalidStashId, setInvalidStashId] = useState(true);
    const [channel, setChannel] = useState();

    const toast = useToast();

    // Note that the default query limit is 100
    // Should be user NFTs x 7776?
    const [chatLimit, setChatLimit] = useState(7776);

    // TODO - store only the "channel" in this object (currently it has also fromId, toId etc..) or maybe we can use that info for notifications
    const [channelList, setChannelList] = useState();
    const [idList, setIdList] = useState([]);

    const [userNFTsInChannel, setUserNFTsInChannel] = useState([]);

    const [mainQuery, setMainQuery] = useState();
    const [watchQuery, setWatchQuery] = useState(null);

    const [displayTransmissionForm, setDisplayTransmissionForm] = useState(false);
    const toggleTrueFalse = () => setDisplayTransmissionForm(!displayTransmissionForm);

    // Get NFTs id list
    useEffect(() => {
        const list = nftList.map(item => {
            const cardId = Object.keys(item)[0];
            const card = item[cardId];

            return card.rarity.lootId;
        })
        setIdList(list)

        if (list.length > 0) {
            let queries = [];
            list.forEach(item => {
                const query = new Moralis.Query(Terminal).contains("channel", "#" + item + "#");
                queries.push(query);
            })

            setMainQuery(new Moralis.Query.or(...queries).limit(chatLimit));
            setWatchQuery(new Moralis.Query.or(...queries).limit(chatLimit));
        }
        // console.log("idList", list);

    }, [nftList, channel])


    // Query the database for channels with the user's NFTs
    const {data, error, isLoading} = useMoralisQuery(Terminal, query =>
        mainQuery, [idList, channel, mainQuery]);

    // TODO
    //########################################################################

    //     const watchChannelsQuery = new Moralis.Query(Terminal);
    //     watchChannelsQuery.contains("channel", "#764#");

    // Subscribe to the channel to get new messages

    // useMoralisSubscription(Terminal, query =>
    //         watchQuery
    // TODO - refactor the query to only listen to users nfts (now it receives all the messages)
    useMoralisSubscription(Terminal, query =>
            query
        , [watchQuery], {
            onCreate: data => {
                let newMessage = JSON.stringify(data, null, 2)
                newMessage = JSON.parse(newMessage);
                //console.log("newMessage", newMessage);

                let channel = newMessage.channel;

                // if the new message regards this users NFTs then add the channel
                // why would the other app receive all the notifications? it's wrong
                //console.log("idList", idList);
                //console.log("newMessage.toId", parseInt(newMessage.toId))
                //console.log("idList.includes(parseInt(newMessage.toId))", idList.includes(parseInt(newMessage.toId)));
                if (idList.includes(parseInt(newMessage.toId)) || idList.includes(parseInt(newMessage.fromId))) {

                    let tempChannelsList = [
                        ...channelList,
                        newMessage
                    ];
                    // Get the unique channels for the logged user
                    const uniqueChannels = [...new Map(tempChannelsList.map(item =>
                        [item.channel, item])).values()];

                    setChannelList(uniqueChannels);
                    // TODO - remove all the junk from uniqueChannels, leave only the ids
                    //console.log("uniqueChannels", uniqueChannels)
                }
            },
            enabled: true,
        });
    //########################################################################

    useEffect(() => {
        if (data) {
            let channelList = JSON.stringify(data, null, 2);
            channelList = JSON.parse(channelList);

            // Get the unique channels for the logged user
            const uniqueChannels = [...new Map(channelList.map(item =>
                [item.channel, item])).values()];

            setChannelList(uniqueChannels);
            // console.log("uniqueChannels", uniqueChannels);
        }
    }, [data])


    function openTransmissionForm() {
        setChannel(null);
        setDisplayTransmissionForm(true);
    }

    function handleSetChannel(item) {
        // Check what NFTs the user owns between the 2.
        // Set the one found between the 2 as sendFrom and the other to sendTo
        // If the user owns both NFTs, we set the first as sendFrom and the other to sendTo
        let from = item.fromId;
        let to = item.toId;

        setUserNFTsInChannel([]);
        let tempList = [];

        if (idList.includes(parseInt(from))) {
            setSendFrom(from);
            setSendTo(to);
            tempList.push(parseInt(from));
        }

        if (idList.includes(parseInt(to))) {
            setSendFrom(to);
            setSendTo(from);
            tempList.push(parseInt(to));
        }

        setUserNFTsInChannel(tempList);

        // console.log("idList", idList);
        // console.log("tempList", tempList);

        // Set the channel
        setChannel(item.channel);

        //Hide the display transmission form
        setDisplayTransmissionForm(false);
    }

    function onSelectFromChange(e) {
        setSendFrom(e.target.value)
    }

    function onSendToInputChange(e) {
        let from = selectFromRef.current.value;
        let to = e.target.value;

        // Make sure that we have a valid number
        if (isStashIdInvalid(to, 1, 7777)) {
            setInvalidStashId(true);
                toast({
                    title: "ERROR",
                    description: "STASH ID MUST BE BETWEEN 1 - 7777",
                    status: "error",
                    duration: 3000,
                    isClosable: true
                })
        // Receiver cannot be the same as the sender
        } else if (Number(to) === Number(from)) {
            setInvalidStashId(true);
            toast({
                title: "ERROR",
                description: "RECEIVER CANNOT BE THE SAME AS THE SENDER",
                status: "error",
                duration: 3000,
                isClosable: true
            })
        }
        else {
            setInvalidStashId(false);
            setSendTo(to)
        }
    }

    function startTransmission(e) {
        e.preventDefault()

        setUserNFTsInChannel([]);
        let tempList = [];

        let from = selectFromRef.current.value;
        let to = sendToInputRef.current.value;
        let fromNumber = parseInt(from);
        let toNumber = parseInt(to);

        if (idList.includes(fromNumber)) {
            tempList.push(fromNumber);
        }

        if (idList.includes(toNumber)) {
            tempList.push(toNumber)
        }

        setUserNFTsInChannel(tempList);

        // console.log("sendToInputRef.current.value", sendToInputRef.current.value)
        // console.log("selectFromRef.current.value", selectFromRef.current.value)
        // console.log("idList", idList);
        // console.log("tempList", tempList);

        setSendFrom(from);
        setSendTo(to);
        setChannel(getChannelName(from, to));
        setDisplayTransmissionForm(false);
    }

    return (
        <>
            <HStack h="100%" w="100%">
                <VStack textTransform="uppercase" align={"flex-start"} textShadow={"none"} h="100%" w="40%"
                        borderRight={"2px solid #1AFF80"}>
                    <Box w="100%" h="30%" borderBottom={"14px solid #1AFF80"} p={5}>
                        <Stack h="100%" spacing={5}>
                            <Text>GEAR TRANSMISSIONS</Text>

                            {channelList && channelList.length === 0 &&
                            <Text>
                                YOU HAVE {channelList.length} TRANSMISSIONS
                            </Text>
                            }

                            {channelList && channelList.length > 0 &&
                            <Text>
                                YOU
                                HAVE {channelList.length} {channelList.length === 1 ? "TRANSMISSION" : "TRANSMISSIONS"}
                            </Text>
                            }

                            <Spacer/>
                            {/*TODO - the sendFrom and sendTo FORM fields to cannot be the same - add validation to prevent that*/}
                            <Button onClick={openTransmissionForm}>
                                INITIATE A NEW TRANSMISSION
                            </Button>
                        </Stack>
                    </Box>
                    <Box w="100%" h="70%">
                        <Box w="100%" h="100%">

                            {channelList && channelList.length > 0 &&
                            <Box w={"100%"} height="100%" overflow={"scroll"} p={3}>
                                {
                                    channelList.map((item) => (
                                        <Button
                                            w={"200px"}
                                            h={"50px"}
                                            m={2}
                                            variant="solid"
                                            key={item.channel}
                                            onClick={() => handleSetChannel(item)}
                                        >
                                            #{item.fromId}
                                            <Icon as={BsArrowLeftRight} mx={2} w="30px"/>
                                            #{item.toId}
                                        </Button>
                                    ))
                                }
                            </Box>
                            }
                        </Box>
                    </Box>
                </VStack>
                <Square h="100%" w="60%">

                    {channel &&
                    <VStack h="100%" w="100%">
                        <Box w="100%" h="30%" p={5} borderBottom={"1px solid #1AFF80"}>
                            <HStack>
                                <IconButton
                                    onClick={() => {
                                        setChannel(null)
                                    }}
                                    icon={<RiCloseLine/>}
                                    aria-label="CLOSE"
                                />
                                <Text textTransform={"uppercase"} pl={5}>
                                    Connected GΞAR: #{sendFrom} <Icon as={BsArrowLeftRight}/> #{sendTo}
                                    {/*Channel: {channel}*/}
                                </Text>

                            </HStack>

                        </Box>
                        <Box w="100%" h="70%">
                            <Channel
                                channel={channel}
                                from={sendFrom}
                                setSendFrom={setSendFrom}
                                to={sendTo}
                                setSendTo={setSendTo}
                                idList={idList}
                                userNFTsInChannel={userNFTsInChannel}
                            />
                        </Box>
                    </VStack>
                    }

                    {displayTransmissionForm && idList.length > 0 &&
                    <VStack w="100%" h="100%">
                        <Box w="100%" h="30%" p={5} borderBottom={"1px solid #1AFF80"}>
                            <Text textTransform={"uppercase"}>INITIATE TRANSMISSION</Text>
                        </Box>
                        <Box w="100%" h="70%">
                            <form onSubmit={startTransmission}>
                                <VStack w={"100%"} height={"700px"} flexDir={"column"} alignItems={"flex-start"} p={5}>

                                    <Text>FROM</Text>
                                    <Select value={sendFrom} onChange={onSelectFromChange} ref={selectFromRef}
                                            variant="filled" py={3} px={1}>
                                        {
                                            idList.map((option) => (
                                                <option key={option} value={option}>
                                                    STASH #{option} >
                                                </option>
                                            ))
                                        }
                                    </Select>

                                    <FormControl isRequired>
                                        <FormLabel>TO</FormLabel>
                                        <NumberInput min={1} max={7777}>
                                            <NumberInputField
                                                placeholder="STASH ID"
                                                size="md"
                                                ref={sendToInputRef}
                                                onChange={onSendToInputChange}
                                            />
                                            <NumberInputStepper>
                                                <NumberIncrementStepper/>
                                                <NumberDecrementStepper/>
                                            </NumberInputStepper>
                                        </NumberInput>
                                    </FormControl>

                                    <HStack pt={5}>
                                        <Button type="submit" isDisabled={invalidStashId}>
                                            START TRANSMISSION
                                        </Button>
                                        <Button onClick={() => setDisplayTransmissionForm(false)}>
                                            CANCEL
                                        </Button>
                                    </HStack>

                                </VStack>
                            </form>
                        </Box>
                    </VStack>
                    }

                </Square>
            </HStack>
        </>
    )
}

export default Transmissions;