import {useEffect, useState} from "react";
import {useMoralis, useMoralisWeb3Api} from "react-moralis";

/**
 * Retrieves the NFTs for the specified NFT collection owned by the specified user on the specified network.
 * Note that the user must be authenticated.
 *
 * eg. GEAR tokenAddress: 0xFf796cbbe32B2150A4585a3791CADb213D0F35A3
 */
function useNFTs(chain, userAddress, tokenAddress) {

    const [value, setValue] = useState([]);
    const [totalNFTs, setTotalNFTs] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const {isAuthenticated} = useMoralis();
    const Web3Api = useMoralisWeb3Api();

    useEffect( () => {
        if (isAuthenticated) {
            setIsLoading(true);
            getNFTs()
                .then(() => setIsLoading(false))
        }
    }, [isAuthenticated, chain, userAddress, tokenAddress])

    // TODO - handle server ERROR!
    async function getNFTs() {
        const data = await Web3Api.account.getNFTsForContract({chain: chain, address: userAddress, token_address: tokenAddress});
        setValue(data.result);
        setTotalNFTs(data.total);
    }

    // Cleanup function
    // TODO - cancel data fetching from Moralis - otherwise we get
    // "Can't perform a React state update on an unmounted component."
    // see https://dmitripavlutin.com/react-cleanup-async-effects/

    return [value, totalNFTs, isLoading];
}

export default useNFTs;