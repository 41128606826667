import {VStack} from "@chakra-ui/react";
import classes from "./UserNotLoggedIn.module.css"
import {useEffect} from "react";
import {boot} from "../helpers/boot";

function UserNotLoggedIn() {

    // const [loop, setLoop] = useState(true);

    useEffect(() => {
        async function welcome() {
            //while (loop) {
            await boot();
            //}
        }
        welcome();
    }, [])

    return (
        <VStack className={classes.vStack} >

            {/*<div className={classes.input}> GΞAR TΞRMINAL*/}
            {/*</div>*/}

            {/*<div id="screen">*/}
            {/*    <div id="crt">*/}
                    <div className="terminal">
                    </div>
                {/*</div>*/}
            {/*</div>*/}
        </VStack>
    )
}

export default UserNotLoggedIn;