import {Box, Flex, HStack, Link, Spacer, Square, Stack, Text, VStack} from "@chakra-ui/react";
import Card from "./ui/Card";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import classes from "./ItemDetails.module.css"
import {useMoralis, useMoralisWeb3Api} from "react-moralis";
import useJSONData from "../hooks/useJSONData";
import {colorForOccurrence, percentageForOccurrence} from "../helpers/rarity";
import Legend from "./Legend";
import Frame from "./ui/Frame";
import {neuroABI} from "../abi/neuro"
import useNeuroClaimed from "../hooks/useNeuroClaimed";

function ItemDetails(props) {

    const GEAR_COLLECTION_ENDPOINT = "https://opensea.io/assets/0xff796cbbe32b2150a4585a3791cadb213d0f35a3";
    const {isAuthenticated, isWeb3Enabled, enableWeb3, web3, Moralis} = useMoralis();
    const Web3Api = useMoralisWeb3Api();
    const history = useHistory();
    const {id} = useParams();
    const [neuroClaimed, isNeuroClaimedLoading, neuroClaimedError] = useNeuroClaimed(0, id);
    const [cardData, isLoading] = useJSONData('../data.json');
    const [isSearching, setIsSearching] = useState(false);
    const [card, setCard] = useState();

    // TODO - HANDLE not being on ETH network!
    //  Info like Neuro claimed will not be available if we are on other networks!
    // TODO - enable web3 as soon as the user logs is or opens the home page
    //const netId = web3.eth.net.getId();
    //console.log("Network id:", netId);

    useEffect(() => {

        async function findCard() {
            if (cardData[id - 1] && cardData[id - 1][id.toString()]) {
                let card = cardData[id - 1][id.toString()];
                setCard(card);
            }
        }
        setIsSearching(true);
        findCard().then(() => setIsSearching(false));

    }, [cardData, id])


    // Handle card not found
    // - if url param contains non numeric characters
    // - card number not between 1 - 7777
    if (id.match(/[^$,.\d]/) || parseInt(id, 10) > 7777 || parseInt(id, 10) === 0) {
        history.replace('/notFound')
    }

    if (!isAuthenticated) {
        history.replace('/')
    }

    return (
        <>
            {/*<Menu/>*/}
            <Frame>
                {isAuthenticated && card &&
                <HStack h="100%" w="100%">
                    <VStack textTransform="uppercase" align={"flex-start"} textShadow={"none"} h="100%" w="40%"
                            borderRight={"2px solid #1AFF80"}>
                        <Box w="100%" h="30%" borderBottom={"14px solid #1AFF80"} p={5}>
                            <Box pl={1} fontSize={"2xl"}>
                                {card.metadata.name}
                            </Box>
                            <Stack fontSize={"md"} pl={1} pt="50px" spacing={3}>
                                <Box>
                                    Score: {card.rarity.score}
                                </Box>

                                <Box>
                                    Rarity: #{card.rarity.rarest}/7777
                                </Box>

                                <Box>
                                    <Link className={classes.link} href={`${GEAR_COLLECTION_ENDPOINT}/${id}`}
                                          isExternal>
                                        View on OpenSea
                                    </Link>
                                </Box>
                            </Stack>
                        </Box>
                        <Box w="100%" h="70%" p={5}>
                            <HStack>
                                <Box>NEURO Claimed:</Box>
                                <Box>
                                    {isNeuroClaimedLoading && "LOADING..."}
                                    {!isNeuroClaimedLoading && neuroClaimed}
                                    {!isNeuroClaimedLoading && neuroClaimedError && "UNKNOWN" }
                                </Box>
                            </HStack>
                            <Spacer/>
                            <Box mt="420px">
                                <Legend/>
                            </Box>

                        </Box>
                    </VStack>
                    <Square h="100%" w="60%">
                        <Box mt={5}>
                            <Card width="402px">
                                <VStack textTransform="uppercase" fontSize={"2xl"} align={"flex-start"}
                                        textShadow="none" p={3}>
                                    <HStack p={1}>
                                        <Box className={classes.name} color={colorForOccurrence(card.items.chest.oc)}>
                                            CHEST
                                        </Box>
                                        <Spacer/>
                                        <Box className={classes.details}
                                             color={colorForOccurrence(card.items.chest.oc)}>
                                            <Box fontSize={"md"}>{card.items.chest.name}</Box>
                                            {/*<Box color={colorForOccurrence(card.items.chest.oc)}>{categoryForOccurrence(card.items.chest.oc)}</Box>*/}
                                            <Box fontSize={"md"}>{percentageForOccurrence(card.items.chest.oc)}% have
                                                this
                                                trait</Box>
                                        </Box>
                                    </HStack>

                                    <HStack p={1}>
                                        <Box className={classes.name} color={colorForOccurrence(card.items.foot.oc)}>
                                            FOOT
                                        </Box>
                                        <Spacer/>
                                        <Box className={classes.details} color={colorForOccurrence(card.items.foot.oc)}>
                                            <Box fontSize={"md"}>{card.items.foot.name}</Box>
                                            {/*<Box>{categoryForOccurrence(card.items.foot.oc)}</Box>*/}
                                            <Box fontSize={"md"}>{percentageForOccurrence(card.items.foot.oc)}% have
                                                this
                                                trait</Box>
                                        </Box>
                                    </HStack>

                                    <HStack p={1}>
                                        <Box className={classes.name} color={colorForOccurrence(card.items.hand.oc)}>
                                            HAND
                                        </Box>
                                        <Spacer/>
                                        <Box className={classes.details} color={colorForOccurrence(card.items.hand.oc)}>
                                            <Box fontSize={"md"}>{card.items.hand.name}</Box>
                                            {/*<Box>{categoryForOccurrence(card.items.hand.oc)}</Box>*/}
                                            <Box fontSize={"md"}>{percentageForOccurrence(card.items.hand.oc)}% have
                                                this
                                                trait</Box>
                                        </Box>
                                    </HStack>

                                    <HStack p={1}>
                                        <Box className={classes.name} color={colorForOccurrence(card.items.head.oc)}>
                                            HEAD
                                        </Box>
                                        <Spacer/>
                                        <Box className={classes.details} color={colorForOccurrence(card.items.head.oc)}>
                                            <Box fontSize={"md"}>{card.items.head.name}</Box>
                                            {/*<Box>{categoryForOccurrence(card.items.head.oc)}</Box>*/}
                                            <Box fontSize={"md"}>{percentageForOccurrence(card.items.head.oc)}% have
                                                this
                                                trait</Box>
                                        </Box>
                                    </HStack>

                                    <HStack p={1}>
                                        <Box className={classes.name} color={colorForOccurrence(card.items.implant.oc)}>
                                            IMPLANT
                                        </Box>
                                        <Spacer/>
                                        <Box className={classes.details}
                                             color={colorForOccurrence(card.items.implant.oc)}>
                                            <Box fontSize={"md"}>{card.items.implant.name}</Box>
                                            {/*<Box>{categoryForOccurrence(card.items.implant.oc)}</Box>*/}
                                            <Box fontSize={"md"}>{percentageForOccurrence(card.items.implant.oc)}% have
                                                this
                                                trait</Box>
                                        </Box>
                                    </HStack>

                                    <HStack p={1}>
                                        <Box className={classes.name} color={colorForOccurrence(card.items.eyes.oc)}>
                                            EYES
                                        </Box>
                                        <Spacer/>
                                        <Box className={classes.details} color={colorForOccurrence(card.items.eyes.oc)}>
                                            <Box fontSize={"md"}>{card.items.eyes.name}</Box>
                                            {/*<Box>{categoryForOccurrence(card.items.eyes.oc)}</Box>*/}
                                            <Box fontSize={"md"}>{percentageForOccurrence(card.items.eyes.oc)}% have
                                                this
                                                trait</Box>
                                        </Box>
                                    </HStack>

                                    <HStack p={1}>
                                        <Box className={classes.name} color={colorForOccurrence(card.items.weapon.oc)}>
                                            WEAPON
                                        </Box>
                                        <Spacer/>
                                        <Box className={classes.details}
                                             color={colorForOccurrence(card.items.weapon.oc)}>
                                            <Box fontSize={"md"}>{card.items.weapon.name}</Box>
                                            {/*<Box>{categoryForOccurrence(card.items.weapon.oc)}</Box>*/}
                                            <Box fontSize={"md"}>{percentageForOccurrence(card.items.weapon.oc)}% have
                                                this
                                                trait</Box>
                                        </Box>
                                    </HStack>

                                    <HStack p={1}>
                                        <Text className={classes.name} color={colorForOccurrence(card.items.waist.oc)}>
                                            WAIST
                                        </Text>
                                        <Spacer/>
                                        <Box className={classes.details}
                                             color={colorForOccurrence(card.items.waist.oc)}>
                                            <Box fontSize={"md"}>{card.items.waist.name}</Box>
                                            {/*<Box>{categoryForOccurrence(card.items.waist.oc)}</Box>*/}
                                            <Box fontSize={"md"}>{percentageForOccurrence(card.items.waist.oc)}% have
                                                this
                                                trait</Box>
                                        </Box>
                                    </HStack>
                                </VStack>
                            </Card>
                        </Box>
                    </Square>
                </HStack>
                }
                {isLoading && <Box p={3}>LOADING</Box>}
                {isSearching && <Box p={3}>SEARCHING</Box>}
            </Frame>
        </>
    )
}

export default ItemDetails;