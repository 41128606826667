/**
 * Formats and returns the channel name.
 * eg. If the communication is between STASH #70 and STASH #45 -> channel name will be #45#70
 */
export function getChannelName(from, to) {
    return "#" +
        Math.min(parseInt(from, 10), parseInt(to, 10)) +
        "#" +
        Math.max(parseInt(from, 10), parseInt(to, 10)) +
        "#";
}

/**
 * Checks if the message to be send is invalid
 *
 * @param message the message to check
 * @returns {boolean} true if:
 * - the message is an empty string
 */
export function isMessageToSendInvalid(message) {
    return message.trim() === "";
}