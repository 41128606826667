import React from "react";
import NFTItem from "./NFTItem";
import {Box, Flex, HStack, VStack} from "@chakra-ui/react";
import classes from "./NFTsList.module.css"
import {AutoSizer, List} from "react-virtualized";
import Legend from "./Legend";

/**
 * Outputs the list of NFTs given
 */
function NFTsList({nftList, title}) {

    // const listHeight = 400;
    const rowHeight = 450;
    // const rowWidth = 800;

    function renderCard({index, key, style}) {
        return (
            <HStack key={key} style={style} p={5}>
                 <NFTItem key={key} item={nftList[index]}/>
            </HStack>
        )
    }

    return (
        <Flex>
            {nftList &&
                <VStack>
                    <Box w="1100px" h="600px" mt="10px">
                        <AutoSizer>
                            {({width, height}) => (
                                <List
                                    width={width}
                                    height={height}
                                    rowHeight={rowHeight}
                                    rowRenderer={renderCard}
                                    rowCount={nftList.length}
                                    overscanRowCount={3}
                                />
                            )}
                        </AutoSizer>
                    </Box>
                    <Legend/>
                </VStack>
            }
        </Flex>
    )
}

export default NFTsList;