import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {ChakraProvider, ColorModeScript} from '@chakra-ui/react';
import {MoralisProvider} from 'react-moralis';
import gearTheme from './theme';

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
                <ChakraProvider theme={gearTheme}>
                    <ColorModeScript/>
                    <App/>
                </ChakraProvider>
            </MoralisProvider>
        </BrowserRouter>
    </React.StrictMode>,
  document.getElementById('root')
);
