import React, {useEffect, useState} from "react";
import NFTsList from "./NFTsList";
import classes from "./UserLoggedIn.module.css";
import useJSONData from "../hooks/useJSONData";
import {Text, VStack} from "@chakra-ui/react";
import useNFTs from "../hooks/useNFTs";
import {useMoralis} from "react-moralis";
import Menu from "./Menu";
import Transmissions from "./Transmissions";
import Frame from "./ui/Frame";

function UserLoggedIn() {

    const {isAuthenticated, user} = useMoralis();
    const gearTokenAddress = "0xFf796cbbe32B2150A4585a3791CADb213D0F35A3";
    const userAddress = user.get("ethAddress");

    const [tab, setTab] = useState(1);

    const [jsonData, isJsonDataLoading] = useJSONData('data.json');
    const [nftData, totalNFTs, isNftDataLoading] = useNFTs("eth", userAddress, gearTokenAddress)

    const [ownedNFTs, setOwnedNFTs] = useState([]);

    // // Sort by score
    // scores = scores.sort((a, b) => a.score - b.score);
    // // Sort by index of score
    // scores = scores.map((loot, i) => ({
    //     ...loot,
    //     rarest: i + 1,
    // }));

    useEffect(() => {

        async function process() {
            // Why read and process the JSON AGAIN? let's just use jsonData!
            return jsonData.filter((item, index) => {
                for (const nft of nftData) {
                    let stashName = "Stash #" + nft.token_id;
                    // TODO - change lootId with stashId
                    if (item[Object.keys(item)[0]]['rarity']['lootId'].toString() === nft.token_id.toString()) {
                        return true;
                    }
                }
            })
        }

        // if the user has any NFTs
        if (jsonData.length > 0 && nftData.length > 0) {
            process()
                .then((res) => setOwnedNFTs(res))
        }
    }, [jsonData, nftData])

    return (
        <>
        <Menu tab={tab} setTab={setTab}/>
            <Frame>
                <VStack h="100%" w="100%">
                    {isJsonDataLoading && <Text textTransform={"uppercase"} p={3} m={3}>LOADING JSON data</Text>}
                    {isNftDataLoading && <Text textTransform={"uppercase"} p={3} m={3}>LOADING NFT data</Text>}

                    {tab === 1 && ownedNFTs && ownedNFTs.length > 0 &&
                    <Transmissions nftList={ownedNFTs}/>
                    }

                    {tab === 2 && ownedNFTs && ownedNFTs.length > 0 &&
                    <>
                        <Text p={3}>
                            TOTAL GΞAR NFTs: {totalNFTs}
                        </Text>
                        <NFTsList nftList={ownedNFTs}/>
                    </>
                    }

                    {tab === 3 && jsonData && jsonData.length > 0 &&
                    <>
                        <Text p={3}>
                            TOTAL NFTs: {jsonData.length}
                        </Text>
                        <NFTsList nftList={jsonData}/>
                    </>
                    }

                    {tab === 1 && totalNFTs === 0 &&
                    <VStack m={3} p={3} textTransform={"uppercase"}>
                        <Text>
                            You have no GΞAR NFTs on this Eth address.
                        </Text>
                        <Text>
                            You need to have at least one GΞAR NFT to use the TRANSMISSIONS feature.
                        </Text>
                    </VStack>
                    }

                    {tab === 2 && totalNFTs === 0 &&
                    <Text m={3} p={3} textTransform={"uppercase"}>
                        You have no GΞAR NFTs on this Eth address.
                    </Text>
                    }

                    {tab === 4 &&
                        <Text p={5}>COMING SOON</Text>
                    }

                    {tab === 5 &&
                    <Text p={5}>COMING SOON</Text>
                    }
                </VStack>
            </Frame>
        </>
    )
}

export default UserLoggedIn;