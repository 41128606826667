import {Flex, Text} from "@chakra-ui/react";
import classes from "./Legend.module.css"
import {colorForScore} from "../helpers/rarity";

function Legend() {
    return (
        <>
            <Flex className={classes.container}>
                {/*<Text className={classes.legendText}>Rarity Legend:</Text>*/}
                <Text color={colorForScore(6)}>Mythic</Text>
                <Text color={colorForScore(5)}>Legendary</Text>
                <Text color={colorForScore(4)}>Epic</Text>
                <Text color={colorForScore(3)}>Rare</Text>
                <Text color={colorForScore(2)}>Uncommon</Text>
                <Text color={colorForScore(1)}>Common</Text>
            </Flex>
        </>
    )
}

export default Legend;