import {extendTheme} from '@chakra-ui/react';

const overrides = {
    config: {
        initialColorMode: 'dark',
    },
    styles: {
        global: {
            body: {
                fontFamily: "Regular3270",
                fontSize: "md"
            }
        }
    },
    colors: {
        brand: {
            light: "#bdb508",
            dark: "#171923",
            gray: "#F5F5F5"
        },
        terminal: {
            background: "#17192320",
            white: "#FFFFFF",
            green: "#63cd18",
            textShadow: "#C8C8C8",
            selection: "#0080FF",

            greenGhoul: "#1AFF80",
            greenMutant: "#12FF15",

            redBlood: "#BB313D",
            purpleEggplant: "#8438F5",

            orangeMojave: "#FFB641",
            yellowIcterine: "#FEF265",
            yellowMediumChampagne: "#FCE8A4",
            papayaWhip: "#FEEED4",

            metallicBlue: "#325886",
            steelBlue: "#447AB9",
            blueNukaCola: "#2ECFFF",
            vistaBlue: "#82A7D6",
        }
    }
};

const gearTheme = extendTheme(overrides);

export default gearTheme;