import classes from "./Frame.module.css"
import {Box, Flex, HStack, Spacer} from "@chakra-ui/react";
import {useMoralis} from "react-moralis";
import {useEffect, useState} from "react";

function Frame(props) {

    const {isWeb3Enabled, web3} = useMoralis();
    const [networkId, setNetworkId] = useState("");

    // Get Network ID
    useEffect(() => {
        if(isWeb3Enabled) {
            async function getNetworkId(web3) {
                return await web3.eth.net.getId();
            }

            getNetworkId(web3).then(result => {
                setNetworkId(result)
            })
        }
    }, [web3, isWeb3Enabled])

    // Subscribe to Network changes
    useEffect(() => {
        if (isWeb3Enabled && window.ethereum) {
            window.ethereum.on('chainChanged', (result) => {
                setNetworkId(parseInt(result, 16));
            });
        }
    }, [web3, isWeb3Enabled, window.ethereum])

    return (
        <>
            <Flex className={classes.frame} flexDir={"column"}>
                {props.children}
            </Flex>
            <HStack className={classes.frameLine}>
                {networkId &&
                    <Box className={classes.network} color={parseInt(networkId) === 1 ? "terminal.greenGhoul" : "terminal.redBlood"}>
                        NETWORK ID {networkId}
                    </Box>
                }
                <Spacer/>
                <Box className={classes.network} color={isWeb3Enabled === true ? "terminal.greenGhoul" : "terminal.redBlood"}>
                    WEB3 {isWeb3Enabled === true ? "ON" : "OFF"}
                </Box>
            </HStack>
        </>
    )
}

export default Frame;