import classes from "./Background.module.css"

/**
 * This represents the background layout that will be used on the pages
 */
function Background(props) {
    return (
        <div className={classes.body}>
            <div className={classes.scanline}>
            </div>
            {props.children}
        </div>
    )
}

export default Background;