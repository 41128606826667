import React, {useEffect} from 'react';
import {Route, Switch} from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Header from "./components/Header"
import './App.css';
import Background from "./components/layout/Background";
import ItemDetails from "./components/ItemDetails";
import {useMoralis} from "react-moralis";

function App() {

    const {isWeb3Enabled, enableWeb3} = useMoralis();

    // Enable web3
    useEffect(() => {
        if (!isWeb3Enabled) {
            enableWeb3();
        }
    }, [isWeb3Enabled, enableWeb3]);

    return (
        <Background>
            <Header/>
            <Switch>
                <Route path='/' exact>
                    <Home/>
                </Route>
                <Route path='/details/:id'>
                    <ItemDetails />
                </Route>
                <Route>
                    <NotFound/>
                </Route>
            </Switch>
        </Background>
    );
}

export default App;
