import React from "react";
import {Box, HStack, Link, Spacer, Text, VStack} from "@chakra-ui/react";
import Card from "./ui/Card";
import classes from "./NFTItem.module.css"
import {Link as LinkRouter} from "react-router-dom";
import {colorForOccurrence} from "../helpers/rarity";

/**
 * This represents a single NFT Item that will be displayed in a list of NFTs
 */
function NFTItem({item}) {

    // TODO - define only in one place (see itemDetails) - export it
    const GEAR_COLLECTION_ENDPOINT = "https://opensea.io/assets/0xff796cbbe32b2150a4585a3791cadb213d0f35a3/";

    const cardId = Object.keys(item)[0];
    const card = item[cardId];

    return (
        <Link className={classes.link} as={LinkRouter} to={`/details/${cardId}`}>
            <Card width="402px">
                <VStack textTransform="uppercase" align={"flex-start"} textShadow={"none"} p={3}>
                        <Box>
                            {card.metadata.name}
                        </Box>
                        <HStack fontSize={"md"} pb={5}>
                            <Box>
                                Score: {card.rarity.score} |
                            </Box>
                            <Spacer/>
                            <Box>
                                Rarity: {card.rarity.rarest}
                            </Box>
                            <Spacer/>
                            <Box>
                                View details
                            </Box>
                        </HStack>

                    <Box color={colorForOccurrence(card.items.chest.oc)}>
                        <Box>{card.items.chest.name}</Box>
                    </Box>
                    <Box color={colorForOccurrence(card.items.foot.oc)}>
                        <Box>{card.items.foot.name}</Box>
                    </Box>
                    <Box color={colorForOccurrence(card.items.hand.oc)}>
                        <Box>{card.items.hand.name}</Box>
                    </Box>
                    <Box color={colorForOccurrence(card.items.head.oc)}>
                        <Box>{card.items.head.name}</Box>
                    </Box>
                    <Box color={colorForOccurrence(card.items.implant.oc)}>
                        <Box>{card.items.implant.name}</Box>
                    </Box>
                    <Box color={colorForOccurrence(card.items.eyes.oc)}>
                        <Box>{card.items.eyes.name}</Box>
                    </Box>
                    <Box color={colorForOccurrence(card.items.weapon.oc)}>
                        <Box>{card.items.weapon.name}</Box>
                    </Box>
                    <Box color={colorForOccurrence(card.items.waist.oc)}>
                        <Box>{card.items.waist.name}</Box>
                    </Box>
                </VStack>
            </Card>
        </Link>
    )
}

export default NFTItem;