import React from "react";
import {useMoralis} from "react-moralis";
import UserLoggedIn from "../components/UserLoggedIn";
import UserNotLoggedIn from "../components/UserNotLoggedIn";

/**
 * Home page
 */
function Home() {

    const {isAuthenticated} = useMoralis();

    return (
        <>
            {isAuthenticated && <UserLoggedIn/>}
            {!isAuthenticated && <UserNotLoggedIn/>}
        </>
    )
}

export default Home;