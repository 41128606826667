import {useEffect, useState} from "react";

/**
 * Reads the NFT rarity data from the specified JSON file
 */
function useJSONData(jsonFile) {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchJSON() {
            // TODO - handle file not found
            let response = await fetch(jsonFile);
            response = await response.json();
            //response = await JSON.parse(response);
            setData(response);
        }
        setIsLoading(true);
        fetchJSON().then(() => setIsLoading(false))
    }, [jsonFile])

    return [data, isLoading]
}

export default useJSONData;