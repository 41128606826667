import React from "react";
import {Center, Flex, Link, Spacer} from "@chakra-ui/react";
import {Link as LinkRouter} from "react-router-dom";
import Authentication from "./Authentication";
import classes from "./Header.module.css"

/**
 * Header component
 */
function Header() {
    return (
        <Flex className={classes.container}>
            <Center>
                <Link className={classes.link} as={LinkRouter} to="/">
                    GΞAR TΞRMINAL
                </Link>
            </Center>
            <Spacer/>
            <Authentication/>
        </Flex>
    )
}

export default Header;