import {Button, HStack} from "@chakra-ui/react";
import classes from "./Menu.module.css"

function Menu({tab, setTab}) {

    const getTextColor = (ourTab, currentTab) =>  currentTab === ourTab ? "terminal.greenGhoul" : "";

    return (
        <>
            <HStack h="100px" w="100%" pl="90px" position="fixed" top="70px">
                <Button onClick={() => setTab(1)} variant="ghost" textColor={getTextColor(1, tab)}>
                    TRANSMISSIONS
                </Button>
                <Button onClick={() => setTab(2)} variant="ghost" textColor={getTextColor(2, tab)}>
                    INVENTORY
                </Button>
                <Button onClick={() => setTab(3)} variant="ghost" textColor={getTextColor(3, tab)}>
                    GEAR COLLECTION
                </Button>
                <Button onClick={() => setTab(4)} variant="ghost" textColor={getTextColor(4, tab)}>
                    UNBUNDLER
                </Button>
                <Button onClick={() => setTab(5)} variant="ghost" textColor={getTextColor(5, tab)}>
                    MARKETPLACE
                </Button>
            </HStack>
        </>
    )
}

export default Menu;